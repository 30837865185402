/* Not actually used but it's easier to have it here than to hack Gatsby+NetlifyCMS not to build it */
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

export const TurtlefilmTemplate = ({
    film_name,
    film_image
}) => {

  return (
    <div>Turtle film goes here - film is {film_name} </div>
  )
};

const TurtlefilmPage = ({ data }) => {
  const { markdownRemark: film } = data;

  return (
    <Layout>
      <TurtlefilmTemplate
        film_name={film.frontmatter.film_name}
        film_image={film.frontmatter.film_image}
      />
    </Layout>
  )
};

export default TurtlefilmPage

export const pageQuery = graphql`
  query TurtlefilmByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
          film_name
          film_image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    }
  }
`;
